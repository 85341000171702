.loader-linear td div {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  border-radius: 9999px;
  color: transparent !important;
  background: linear-gradient(to right, #eaeaea 8%, #d4d4d4 38%, #eaeaea 54%);
  background-size: 1000px 640px;
  position: relative;
}

.loading p,
.loading a,
.loading h2,
.loading label,
.loading select,
.loading input,
.loading span,
.loading .placeholderLoader,
.loading button {
  width: fit-content;
  background-color: transparent !important;
  color: transparent !important;
  border-radius: 8px !important;
  margin: 0px 10px 2px 4px !important;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eaeaea 8%, #d4d4d4 38%, #eaeaea 54%);
  background-size: 1000px 640px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.loading-opacity {
  animation: skeleton-loading 1s linear infinite alternate;
  border-radius: 9999px;
}

@keyframes skeleton-loading {
  0% {
    background-color: #eaeaea;
    color: #eaeaea;
  }
  100% {
    background-color: #d4d4d4;
    color: #d4d4d4;
  }
}
